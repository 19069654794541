<!-- 预售数据看板 -->
<template>
  <div class="main flex flex-d ai-c">
    <div class="view-title">2023年双十一全网第一波正式活动销售数据</div>
    <div class="return-page" @click="returnPage">
      <Icon type="md-return-left" />
    </div>
    <div class="content flex flex-1 jc-c">
      <div class="left">
        <div class="sub-title">
          <img src="../../../assets/speaial_activity_img/icon2.png" alt="">
          <p class="name">综合电商平台销售额占比</p>
        </div>
        <div class="echart-view">
          <PieChart3D :data="pieData" :valueShow="false" :colorArr="color" :labelLine="false"></PieChart3D>
        </div>
      </div>
      <div class="right">
        <div class="sub-title">
          <img src="../../../assets/speaial_activity_img/icon2.png" alt="">
          <p class="name">综合电商销售额排名TOP10品类</p>
        </div>
        <div class="ranking-view">
          <div class="ranking-head">
            <p style="width:8%">排名</p>
            <p style="width:40%">品类</p>
            <p style="width:20%">销售额（亿元）</p>
            <p style="width:20%">占比</p>
          </div>
          <div class="ranking-content" v-for="(item,index) in list" :key="index">
            <p class="level" style="width:8%">{{item.ranking}}</p>
            <p class="category" style="width:40%">{{item.category}}</p>
            <p class="proportion" style="width:20%">{{ item.sales }}</p>
            <p class="num" style="width:20%">{{item.yoy}}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-hint">
      <p>监测平台：B2C平台（天猫、京东、拼多多等）</p>
      <p class="m">数据监测周期：2023年10月31日-2023年11月3日</p>
      <p>数据来源：星图数据 SYNTUN</p>
    </div>
  </div>
</template>

<script>
import PieChart3D from '@/components/Big/PieChart3D'
import axios from 'axios'
export default {
  name: '',
  components: {
    PieChart3D,
  },
  data() {
    return {
      list: [],
      pieData: [],
      color: ['#FEC000', '#E85137', '#6862D9'],
    }
  },
  computed: {},
  watch: {},
  methods: {
    getData() {
      let url = 'apis/eleven/multipleSales?type=2'
      if (process.env.NODE_ENV !== 'development') {
        // url = 'http://58.18.32.146:17008/eleven/multipleSales?type=2'
        url = 'https://code.synlen.com/eleven/multipleSales?type=2'
      }
      axios.get(url).then((res) => {
        let { code, body, msg } = res.data
        if (code == 200) {
          this.list = body
        }
      })
    },
    getPieData() {
      let url = 'apis/eleven/platformSales?type=2'
      if (process.env.NODE_ENV !== 'development') {
        // url = 'http://58.18.32.146:17008/eleven/platformSales?type=2'
        url = 'https://code.synlen.com/eleven/platformSales?type=2'
      }
      axios.get(url).then((res) => {
        let { code, body, msg } = res.data
        if (code == 200) {
          // this.init(body)
          this.pieData = [
            { name: '天猫', value: body.tmSalesProportion * 100 },
            { name: '京东', value: body.jdSalesProportion * 100 },
            { name: '其他', value: body.otherSalesProportion * 100 },
          ]
        }
      })
    },
    returnPage() {
      this.$emit('changeTemplat', 'CountDown')
    },
  },
  created() {},
  // mounted() {
  //   this.getData()
  //   this.getPieData()
  // },
  activated() {
    this.getData()
    this.getPieData()
  },
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
}
</script>
<style lang='scss' scoped>
@import '@/style/remTwo.scss';
.main {
  background: rgba(0, 0, 0, 0.4);
  position: absolute;
  width: 100vw;
  height: 100vh;
  .view-title {
    font-size: rem(36);
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #ffffff;
    line-height: rem(32);
    background: linear-gradient(180deg, #ffffff 0%, #ffd580 99.5849609375%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-top: rem(76);
  }
}
.footer-hint {
  display: flex;
  align-items: center;
  font-size: rem(18);
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #fafaf6;
  height: rem(40);
  .m {
    margin: 0 rem(60);
  }
}
.content {
  width: 80%;
  margin-top: rem(90);
  .left {
    width: 40%;
  }
  .right {
    width: 40%;
  }
}
.sub-title {
  display: flex;
  align-items: center;
  img {
    height: rem(16);
    margin-right: rem(10);
  }
  .name {
    font-size: rem(26);
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #fafaf6;
  }
}
.echart-view {
  width: 100%;
  height: rem(400);
  margin-top: 20%;
}
.ranking-view {
  margin-top: rem(50);
  .ranking-head {
    font-size: rem(16);
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #fafaf6;
    display: flex;
    justify-content: space-between;
    text-align: center;
    margin-bottom: rem(20);
  }
  .ranking-content:nth-of-type(even) {
    .category,
    .proportion,
    .num {
      height: rem(45);
      line-height: rem(45);
      background: linear-gradient(
        -90deg,
        rgba(255, 153, 0, 0.7),
        rgba(255, 228, 0, 0.7)
      );
      border-radius: 5px;
    }
    .level {
      width: rem(48);
      height: rem(48);
      background: linear-gradient(
        -90deg,
        rgba(255, 153, 0, 0.7),
        rgba(255, 228, 0, 0.7)
      );
      border-radius: 24px;
      line-height: rem(48);
      font-weight: 500;
    }
  }

  .ranking-content {
    display: flex;
    justify-content: space-between;
    font-size: rem(18);
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #ffffff;
    margin-bottom: rem(20);
    .level {
      width: rem(48);
      height: rem(48);
      background: rgba(255, 228, 0, 0.7);
      border-radius: 24px;
      line-height: rem(48);
      font-weight: 500;
    }
    .category,
    .proportion,
    .num {
      height: rem(45);
      line-height: rem(45);
      background: rgba(255, 228, 0, 0.7);
      border-radius: 5px;
    }
  }
}
.return-page {
  width: 50px;
  height: 40px;
  background: #af9736;
  border-radius: 10px;
  position: absolute;
  top: rem(20);
  right: rem(10);
  font-size: rem(40);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  cursor: pointer;
}
</style>
<template>
  <div class="echart-view">
    <div class="pie" ref="pieRef"></div>
    <div class="legend">
      <div :class="['legend-item',{boder:labelLine}]" v-for="(item,index) in data" :key="index" :style="'color:'+colorArr[index]">
        <div class="dian" :style="'background:'+colorArr[index]"></div>
        <p class="name">{{item.name}}</p>
        <p v-if="valueShow" class="value">{{item.value}}%</p>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import 'echarts-gl'
export default {
  name: 'MirrorAppF1',
  props: {
    data: {
      type: [Object, Array],
    },
    valueShow: {
      type: Boolean,
      default: true,
    },
    colorArr: {
      type: Array,
      default: () => {
        return [
          'rgba(255, 244, 144, 1)',
          'rgba(222, 106, 73, 1)',
          'rgba(196, 231, 254, 1)',
          'rgba(255, 153, 0, 1)',
        ]
      },
    },
    labelLine: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      chartId: 'ec-canvasTwo',
      height: 400,
      pieData: [], // 饼图数据
      chart: null,
    }
  },
  watch: {
    data: {
      handler(val) {
        this.init()
      },
      deep: true,
    },
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      if (Object.keys(this.data).length === 0) return
      //扇形颜色
      this.pieData = this.data.map((res, index) => {
        return {
          name: res.name,
          // value: parseFloat(res.value),
          value: res.value,
          itemStyle: {
            color: this.colorArr[index],
            opacity: 0.7,
          },
          startRatio: 0,
          endRatio: 0.7,
        }
      })
      this.setOption()
    },
    getPie3D(pieData, internalDiameterRatio) {
      //internalDiameterRatio:透明的空心占比
      let series = []
      let sumValue = 0
      let startValue = 0
      let endValue = 0
      let k = 1
      pieData.sort((a, b) => {
        return b.value - a.value
      })
      // 为每一个饼图数据，生成一个 series-surface 配置
      for (let i = 0; i < pieData.length; i++) {
        sumValue += pieData[i].value
        let seriesItem = {
          name:
            typeof pieData[i].name === 'undefined'
              ? `series${i}`
              : pieData[i].name,
          type: 'surface',
          parametric: true,
          wireframe: {
            show: false,
          },
          pieData: pieData[i],
          pieStatus: {
            selected: false,
            hovered: false,
            k: k,
          },
          radius: '50%',
          center: ['10%', '10%'],
        }
        if (typeof pieData[i].itemStyle != 'undefined') {
          let itemStyle = {}
          typeof pieData[i].itemStyle.color != 'undefined'
            ? (itemStyle.color = pieData[i].itemStyle.color)
            : null
          typeof pieData[i].itemStyle.opacity != 'undefined'
            ? (itemStyle.opacity = pieData[i].itemStyle.opacity)
            : null
          seriesItem.itemStyle = itemStyle
        }
        series.push(seriesItem)
      }
      // 使用上一次遍历时，计算出的数据和 sumValue，调用 getParametricEquation 函数，
      // 向每个 series-surface 传入不同的参数方程 series-surface.parametricEquation，也就是实现每一个扇形。
      for (let i = 0; i < series.length; i++) {
        endValue = startValue + series[i].pieData.value
        series[i].pieData.startRatio = startValue / sumValue
        series[i].pieData.endRatio = endValue / sumValue
        series[i].parametricEquation = this.getParametricEquation(
          series[i].pieData.startRatio,
          series[i].pieData.endRatio,
          false,
          false,
          k,
          series[i].pieData.value
        )

        //app parametricEquation.x y z都找不到 修改问题--s
        series[i].startRatio = series[i].pieData.startRatio
        series[i].endRatio = series[i].pieData.endRatio
        series[i].isSelected = false
        series[i].isHovered = false
        series[i].k = k
        series[i].h = series[i].pieData.value
        //app parametricEquation.x y z都找不到 修改问题--e

        startValue = endValue
      }
      let boxHeight = this.getHeight3D(series, 25) //通过传参设定3d饼/环的高度，26代表26px
      // 准备待返回的配置项，把准备好的 legendData、series 传入。
      let option = {
        label: {
          show: false,
        },
        xAxis3D: {
          min: -1,
          max: 1,
        },
        yAxis3D: {
          min: -1,
          max: 1,
        },
        zAxis3D: {
          min: -1,
          max: 1,
        },
        grid3D: {
          show: false,
          boxHeight: boxHeight, //圆环的高度
          left: 0,
          top: 0, //3d饼图的位置
          viewControl: {
            //3d效果可以放大、旋转等，请自己去查看官方配置
            alpha: 30, //角度
            distance: 250, //调整视角到主体的距离，类似调整zoom
            rotateSensitivity: 0, //设置为0无法旋转
            zoomSensitivity: 0, //设置为0无法缩放
            panSensitivity: 0, //设置为0无法平移
            autoRotate: false, //自动旋转
          },
        },
        series: series,
      }
      return option
    },
    //获取3d丙图的最高扇区的高度
    getHeight3D(series, height) {
      series.sort((a, b) => {
        return b.pieData.value - a.pieData.value
      })
      return (height * 25) / series[0].pieData.value
    },
    // 生成扇形的曲面参数方程，用于 series-surface.parametricEquation
    getParametricEquation(startRatio, endRatio, isSelected, isHovered, k, h) {
      // 计算
      var midRatio = (startRatio + endRatio) / 2
      var startRadian = startRatio * Math.PI * 2
      var endRadian = endRatio * Math.PI * 2
      var midRadian = midRatio * Math.PI * 2
      // 如果只有一个扇形，则不实现选中效果。
      if (startRatio === 0 && endRatio === 1) {
        isSelected = true
      }
      // 通过扇形内径/外径的值，换算出辅助参数 k（默认值 1/3）
      k = typeof k !== 'undefined' ? k : 1 / 3
      // 计算选中效果分别在 x 轴、y 轴方向上的位移（未选中，则位移均为 0）
      var offsetX = isSelected ? Math.cos(midRadian) * 0.1 : 0
      var offsetY = isSelected ? Math.sin(midRadian) * 0.1 : 0
      // 计算高亮效果的放大比例（未高亮，则比例为 1）
      var hoverRate = isHovered ? 1.05 : 1
      // 返回曲面参数方程
      return {
        u: {
          min: -Math.PI,
          max: Math.PI * 3,
          step: Math.PI / 32,
        },
        v: {
          min: 0,
          max: Math.PI * 2,
          step: Math.PI / 20,
        },
        x: function (u, v) {
          if (u < startRadian) {
            return (
              offsetX +
              Math.cos(startRadian) * (1 + Math.cos(v) * k) * hoverRate
            )
          }
          if (u > endRadian) {
            return (
              offsetX + Math.cos(endRadian) * (1 + Math.cos(v) * k) * hoverRate
            )
          }
          return offsetX + Math.cos(u) * (1 + Math.cos(v) * k) * hoverRate
        },
        y: function (u, v) {
          if (u < startRadian) {
            return (
              offsetY +
              Math.sin(startRadian) * (1 + Math.cos(v) * k) * hoverRate
            )
          }
          if (u > endRadian) {
            return (
              offsetY + Math.sin(endRadian) * (1 + Math.cos(v) * k) * hoverRate
            )
          }
          return offsetY + Math.sin(u) * (1 + Math.cos(v) * k) * hoverRate
        },
        z: function (u, v) {
          if (u < -Math.PI * 0.5) {
            return Math.sin(u)
          }
          if (u > Math.PI * 2.5) {
            return Math.sin(u) * h * 0.1
          }
          return Math.sin(v) > 0 ? 1 * h * 0.1 : -1
        },
      }
    },
    setOption() {
      let option = this.getPie3D(this.pieData, 0.8)
      this.chart = echarts.init(this.$refs.pieRef)
      option && this.chart.setOption(option, false)
    },
    destroyECharts() {
      // 销毁 ECharts 实例
      if (this.chart) {
        this.chart.dispose()
        this.chart = null
      }
    },
  },
  beforeDestroy() {
    this.destroyECharts()
  },
}
</script>

<style lang="scss" scoped>
@import '@/style/rem.scss';
.echart-view {
  width: 100%;
  height: 100%;
  .pie {
    width: 100%;
    height: 80%;
  }
}
.legend {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: rem(20) rem(100);
  padding: 0 rem(40);
  .legend-item {
    display: flex;
    align-items: center;
    font-size: rem(24);
    font-family: Source Han Sans CN;
    font-weight: 400;
  }
  .boder {
    border-bottom: 1px solid;
    // display: grid;
    // grid-template-columns: repeat(3, 1fr);
  }
  .dian {
    width: rem(19);
    height: rem(19);
    border-radius: 50%;
  }
  .name {
    margin: 0 rem(20);
    white-space: nowrap;
  }
  .value {
    flex: 1;
    text-align: right;
  }
}
</style>

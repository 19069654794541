<template>
  <div class="time-view">
    <Counter :num="formattedDays" size="down"></Counter>
    <span class="hint">天</span>
    <Counter :num="formattedHours" size="down"></Counter>
    <span class="hint">小时</span>
    <Counter :num="formattedMinutes" size="down"></Counter>
    <span class="hint">分钟</span>
    <Counter :num="formattedSeconds" size="down"></Counter>
    <span class="hint">秒</span>
  </div>
</template>

<script>
import Counter from './Counter'
export default {
  components: {
    Counter,
  },
  data() {
    return {
      targetDate: new Date('2023-11-11T01:59:59'),
      // targetDate: new Date('2023-11-10T20:49:59'),
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    }
  },
  computed: {
    formattedDays() {
      return this.days < 10 ? '0' + this.days : this.days + ''
    },
    formattedHours() {
      return this.hours < 10 ? '0' + this.hours : this.hours + ''
    },
    formattedMinutes() {
      return this.minutes < 10 ? '0' + this.minutes : this.minutes + ''
    },
    formattedSeconds() {
      return this.seconds < 10 ? '0' + this.seconds : this.seconds + ''
    },
  },
  mounted() {
    // 每秒钟更新一次倒计时
    setInterval(() => {
      // 当前日期和时间
      const currentDate = new Date()
      // 计算剩余时间
      const remainingTime = this.targetDate - currentDate
      if (remainingTime < 0) {
        this.$emit('open')
      }
      // console.log('remainingTime', remainingTime)
      // 计算剩余的天数、小时、分钟和秒数
      this.days = Math.floor(remainingTime / (1000 * 60 * 60 * 24))
      this.hours = Math.floor(
        (remainingTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      )
      this.minutes = Math.floor(
        (remainingTime % (1000 * 60 * 60)) / (1000 * 60)
      )
      this.seconds = Math.floor((remainingTime % (1000 * 60)) / 1000)
    }, 1000)
  },
}
</script>
<style lang="scss" scoped>
@import '@/style/rem.scss';
.time-view {
  display: flex;
  align-items: flex-end;
  .hint {
    margin: 0 rem(10);
    font-size: rem(36);
    font-family: Alimama ShuHeiTi;
    font-weight: bold;
    color: #ffffff;

    background: linear-gradient(
      90deg,
      #654227 0%,
      #eda557 2.83203125%,
      #f9ff89 18.505859375%,
      #f8ff84 50.68359375%,
      #ffd487 65.2587890625%,
      #ae843d 78.7841796875%,
      #f8ff86 86.62109375%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
</style>

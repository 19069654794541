<template>
  <div class="main">
    <p class="title">星图数据2023年双十一全网销售数据直播</p>
    <!-- <div class="close-page" @click="closePage">
      <Icon type="md-close" />
    </div> -->
    <p class="time-hint">倒计时</p>
    <div class="count-down">
      <Time @open="open"></Time>
    </div>
    <div class="card-view">
      <div :class="['card-item','flex','flex-d','ai-c',entrance1?'usable':'disabled']">
        <p class="text-color text">2023年双十一天猫预售数据</p>
        <div :class="['btn']" @click="skitDataTemplate('TmDataTemplate')">
          {{ entrance1?'点击查看':'暂不开放' }}
        </div>
      </div>
      <div :class="['card-item','flex','flex-d','ai-c',entrance2?'usable':'disabled']">
        <p class="text-color text">2023年双十一全网第一波正式活动第一日销售数据</p>
        <div :class="['btn']" @click="skitDataTemplate('OneDataTemplate')" :disabled="!entrance2">
          {{ entrance2?'点击查看':'暂不开放' }}
        </div>
      </div>
      <div :class="['card-item','flex','flex-d','ai-c',entrance3?'usable':'disabled']">
        <p class="text-color text">2023年双十一全网第一波 正式活动销售数据</p>
        <div :class="['btn']" @click="skitDataTemplate('TwoDataTemplate')">
          {{ entrance3?'点击查看':'暂不开放' }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Time from '@/components/Big/Time'
import axios from 'axios'
export default {
  name: '',
  props: {},
  components: {
    Time,
  },
  data() {
    return {
      entrance1: false,
      entrance2: false,
      entrance3: false,
      openCountDownModel: false,
    }
  },
  // created() {
  //   this.getData()
  // },
  activated() {
    this.getData()
  },
  methods: {
    /**
     * @description
     */
    skitDataTemplate(name) {
      if (name == 'TmDataTemplate') {
        this.$emit('changeTemplat', 'TmDataTemplate')
      } else if (name == 'OneDataTemplate' && this.entrance2) {
        this.$emit('changeTemplat', 'OneDataTemplate')
      } else if (name == 'TwoDataTemplate' && this.entrance3) {
        this.$emit('changeTemplat', 'TwoDataTemplate')
      }
    },
    /**
     * @description
     * @param
     */
    getData() {
      let url1 = 'apis/eleven/categoryPreSale'
      let url2 = 'apis/eleven/multipleSales?type=1'
      let url3 = 'apis/eleven/multipleSales?type=2'
      if (process.env.NODE_ENV !== 'development') {
        // url1 = 'http://58.18.32.146:17008/eleven/categoryPreSale'
        // url2 = 'http://58.18.32.146:17008/eleven/multipleSales?type=1'
        // url3 = 'http://58.18.32.146:17008/eleven/multipleSales?type=2'
        url1 = 'https://code.synlen.com/eleven/categoryPreSale'
        url2 = 'https://code.synlen.com/eleven/multipleSales?type=1'
        url3 = 'https://code.synlen.com/eleven/multipleSales?type=2'
      }
      axios.get(url1).then((res) => {
        let { code, body } = res.data
        if (code == 200 && Object.keys(body).length != 0) {
          this.entrance1 = true
        }
      })
      axios.get(url2).then((res) => {
        let { code, body } = res.data
        if (code == 200 && body.length != 0) {
          this.entrance2 = true
        }
      })
      axios.get(url3).then((res) => {
        let { code, body } = res.data
        if (code == 200 && body.length != 0) {
          this.entrance3 = true
        }
      })
    },
    closePage() {
      this.$emit('closePage')
    },
    open() {
      this.$emit('open')
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@/style/remTwo.scss';
.open-count-cown-model {
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 11;
}
.main {
  position: absolute;
  z-index: 12;
  width: 100vw;
  height: 100vh;
  .title {
    font-size: rem(60);
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #ffffff;

    background: linear-gradient(180deg, #ffffff 0%, #ffd580 99.5849609375%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-top: rem(100);
  }
  .time-hint {
    margin-top: rem(50);
    margin-bottom: rem(20);
    font-size: rem(120);
    font-family: Alimama ShuHeiTi;
    font-weight: bold;
    color: #ffffff;
    text-shadow: 0px 4px 0px rgba(255, 208, 99, 0.25);
    background: linear-gradient(
      0deg,
      #654227 0%,
      #eda557 2.83203125%,
      #f9ff89 18.505859375%,
      #f8ff84 50.68359375%,
      #ffd487 65.2587890625%,
      #ae843d 78.7841796875%,
      #f8ff86 86.62109375%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
.count-down {
  min-height: rem(200);
  display: flex;
  justify-content: center;
  margin-bottom: rem(80);
}
.card-view {
  display: flex;
  justify-content: space-between;
  padding: 0 8%;
  .card-item {
    width: rem(480);
    height: rem(280);
    padding: rem(20) rem(40);
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    &:hover {
      -webkit-transform: scale(1.1);
      transform: scale(1.1);
    }
  }
  .text {
    flex: 1;
    font-size: rem(32);
    font-family: Source Han Sans CN;
    font-weight: 500;
    text-align: left;
  }
}
.text-color {
  background: linear-gradient(
    90deg,
    #654227 0%,
    #eda557 2.83203125%,
    #f9ff89 18.505859375%,
    #f8ff84 50.68359375%,
    #ffd487 65.2587890625%,
    #ae843d 78.7841796875%,
    #f8ff86 86.62109375%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.btn {
  width: rem(134);
  height: rem(45);
  line-height: rem(45);
  font-size: rem(20);
  margin-bottom: rem(20);
  cursor: pointer;
}
.usable {
  background: url('../../../assets/speaial_activity_img/card_bg.png') no-repeat
    center;
  background-size: 100% 100%;
  .btn {
    background: url('../../../assets/speaial_activity_img/btn_bg.png') no-repeat
      center;
    background-size: 100% 100%;
    color: #fff2ce;
  }
}
.disabled {
  background: url('../../../assets/speaial_activity_img/card_bg_disabled.png')
    no-repeat center;
  background-size: 100% 100%;
  .btn {
    background: url('../../../assets/speaial_activity_img/btn_bg_disabled.png')
      no-repeat center;
    background-size: 100% 100%;
    color: #ebe8e2;
    cursor: not-allowed;
  }
}
.close-page {
  position: absolute;
  top: rem(20);
  right: rem(10);
  font-size: rem(50);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  cursor: pointer;
}
</style>

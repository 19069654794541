export { };
(function () {
  const docWidth = 1920;
  const doc = window.document;
  const docEl = doc.documentElement;
  const resizeEvt = 'orientationchange' in window ? 'orientationchange' : 'resize';

  const recalc = (function refreshRem() {
    const clientWidth = docEl.getBoundingClientRect().width;
    docEl.style.fontSize = (clientWidth * 17) / docWidth + 'px';
    return refreshRem;
  })();

  if (!doc.addEventListener) {
    return;
  }

  window.addEventListener(resizeEvt, recalc, false);
  doc.addEventListener('DOMContentLoaded', recalc, false);
})();

<!-- 天猫数据看板 -->
<template>
  <div class="main flex flex-d ai-c">
    <div class="view-title">2023年双十一天猫预售数据</div>
    <div class="return-page" @click="returnPage">
      <Icon type="md-return-left" />
    </div>
    <div class="ranking-view flex-1">
      <div class="ranking-item" v-for="(item,key,index) in list" :key="index">
        <div class="ranking-title">
          <p class="name">{{key}}</p>
          <p class="info">TOP10品牌销售额占比及均价</p>
        </div>
        <div class="ranking-list-view">
          <div class="raning-list-head">
            <p class="head-item" style="width: 12%;">排名</p>
            <p class="head-item" style="width: 44%; text-align: left;">品牌名称</p>
            <p class="head-item" style="width: 22%;">占比</p>
            <p class="head-item flex flex-d" style="width: 22%;">
              <span>均价</span>
              <span>（元/件）</span>
            </p>
          </div>
          <div class="raning-list-content" v-for="(sub,i) in item" :key="i">
            <p class="content-item" style="width: 12%;">{{ (i + 1).toString().padStart(2, '0') }}</p>
            <p class="content-item" style="width: 44%; text-align: left;">{{ sub.brand }}</p>
            <p class="content-item" style="width: 22%; text-align: right; padding-right:14px ;">{{ sub.categoryProportion}}</p>
            <p class="content-item" style="width: 22%;text-align: right; padding-right:14px ;">{{ sub.avgSale }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-hint">
      <p>监测平台：天猫</p>
      <p class="m">数据监测周期：2023年10月24日 20:00 - 2023年10月31日 17:59</p>
      <p>数据来源：星图数据 SYNTUN</p>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: '',
  components: {},
  data() {
    return {
      list: {},
    }
  },
  computed: {},
  watch: {},
  methods: {
    init() {
      let url = 'apis/eleven/categoryPreSale'
      if (process.env.NODE_ENV !== 'development') {
        // url = 'http://58.18.32.146:17008/eleven/categoryPreSale'
        url = 'https://code.synlen.com/eleven/categoryPreSale'
      }
      axios.get(url).then((res) => {
        let { code, body, msg } = res.data
        if (code == 200) {
          this.list = body
        }
      })
    },
    returnPage() {
      this.$emit('changeTemplat', 'CountDown')
    },
  },
  created() {},
  // mounted() {
  //   this.init()
  // },
  activated() {
    this.init()
  },
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
}
</script>
<style lang='scss' scoped>
@import '@/style/remTwo.scss';
.main {
  background: rgba(0, 0, 0, 0.4);
  position: absolute;
  width: 100vw;
  height: 100vh;
  .view-title {
    font-size: rem(36);
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #ffffff;
    line-height: rem(32);
    background: linear-gradient(180deg, #ffffff 0%, #ffd580 99.5849609375%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-top: rem(20);
  }
}
.ranking-view {
  width: max-content;
  display: grid;
  grid-template-columns: repeat(4, rem(400));
  grid-gap: rem(20) rem(20);
  margin-top: rem(40);
  margin-bottom: rem(20);
  // margin: 0 auto;
  // justify-items: center;
  .ranking-item {
    max-height: rem(420);
    background: linear-gradient(
      0deg,
      rgba(250, 209, 38, 0.2) 0%,
      rgba(255, 220, 79, 0.2) 100%
    );
    border-radius: rem(30) rem(30) rem(14) rem(14);
    overflow: hidden;
    .ranking-title {
      height: rem(56);
      background: RGBA(193, 158, 54, 1);
      display: flex;
      align-items: center;
      justify-content: center;
      .name {
        font-size: rem(24);
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #ffe400;
        text-shadow: 2px 3px 1px #955f21;
      }
      .info {
        font-size: rem(20);
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #fafaf6;
        text-shadow: 1px 2px 1px #955f21;
        margin: rem(4) 0 0 rem(4);
      }
    }
  }
  .ranking-list-view {
    padding: rem(10);
    .raning-list-head {
      display: flex;
      align-items: center;
      .head-item {
        font-size: rem(16);
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #ffe400;
        line-height: 1;
      }
    }
    .raning-list-content {
      font-size: rem(18);
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #ffffff;
      display: flex;
      align-content: center;
      .content-item {
        font-size: rem(16);
        height: rem(30);
        line-height: rem(30);
      }
    }
  }
}
.footer-hint {
  display: flex;
  align-items: center;
  font-size: rem(18);
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #fafaf6;
  height: rem(40);
  .m {
    margin: 0 rem(60);
  }
}
.return-page {
  width: 50px;
  height: 40px;
  background: #af9736;
  border-radius: 10px;
  position: absolute;
  top: rem(20);
  right: rem(10);
  font-size: rem(40);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  cursor: pointer;
}
</style>
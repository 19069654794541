<!-- 计数器 -->
<template>
  <div class='counter-view'>
    <div :class="[size,getStyle(item)]" v-for="(item,index) in timeNum" :key="index">
      <img :src="getImageUrl(item)" alt="error">
    </div>
  </div>
</template>

<script>
export default {
  name: 'counter',
  props: {
    num: {
      type: String,
    },
    size: {
      type: String,
      default: 'default',
    },
  },
  components: {},
  data() {
    return {
      timeNum: '0',
    }
  },
  computed: {},
  watch: {
    num: {
      handler(val) {
        this.timeNum = val
      },
      immediate: true,
    },
  },
  methods: {
    getImageUrl(number) {
      let path = number == '.' ? 'dian' : number
      return require(`@/assets/speaial_activity_img/image/maxNum/${path}.png`)
    },
    getStyle(number) {
      let style = number == '.' ? 'dian' : ''
      return style
    },
  },
  created() {},
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>
@import '@/style/rem.scss';
.counter-view {
  display: flex;
  align-items: flex-end;
}
.default {
  height: rem(170);
  img {
    width: 100%;
    height: 100%;
  }
}
.dian {
  min-height: rem(70);
  width: rem(48) !important;
  height: rem(70) !important;
  margin-bottom: rem(20) !important;
  img {
    width: rem(48);
    height: rem(70);
  }
}
.down {
  min-height: rem(169);
  max-height: rem(169);
  width: rem(135);
  img {
    width: 100%;
    height: 100%;
  }
}
@media screen and (max-width: 1280px), screen and (max-height: 915px) {
  .default {
    height: rem(150);
    img {
      width: 100%;
      height: 100%;
    }
  }
  .dian {
    min-height: rem(60);
    width: rem(48) !important;
    height: rem(60) !important;
    margin-bottom: rem(0) !important;
    img {
      width: rem(40);
      height: rem(50);
    }
  }
  .down {
    min-height: rem(169);
    max-height: rem(169);
    width: rem(135);
    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import VueAwesomeSwiper from "vue-awesome-swiper";
import ViewUI from 'view-design';
import 'view-design/dist/styles/iview.css';
Vue.use(ViewUI);
import "swiper/swiper-bundle.css";
Vue.use(VueAwesomeSwiper)
import "@/style/common.scss";

Vue.config.productionTip = false;

export const bus = new Vue();
Vue.filter('dateFormat', function (value) {
  const [startDate, endDate] = value.split('-');
  const [startDay, startTime] = startDate.split('日');
  const [endDay, endTime] = endDate.split('日');
  return `Data collected from ${startTime} Nov. ${startDay}th to ${endTime} Nov. ${endDay}th`;
});
Vue.prototype.$dateFormat = function (value) {
  const [startDate, endDate] = value.split('-');
  const [startDay, startTime] = startDate.split('日');
  const [endDay, endTime] = endDate.split('日');
  return `Data collected from ${startTime} Nov. ${startDay}th to ${endTime} Nov. ${endDay}th`;
}
// rem 
import './style/rem.ts'
new Vue({
  router,
  data: () => {
    return {};
  },
  render: h => h(App)
}).$mount("#app");
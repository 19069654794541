<template>
  <div class="video-background">
    <div :class="['open-count-cown-model']">
      <div v-if="countDownOpenState">
        <video autoplay loop muted>
          <source src="@/assets/speaial_activity_img/bg.mp4" type="video/mp4">
        </video>
        <keep-alive>
          <component :is='componentIsName' @changeTemplat="changeTemplat" @closePage="close" @open="open"></component>
        </keep-alive>
      </div>
      <swiper v-if="dataDpOpenState" ref="mySwiper" :options="swiperOptions" class="swiper-box">
        <swiper-slide v-for="(item,index) in languageSwitchComponents" :key="index">
          <component :is="item.name" :data="item.dataName=='default'? levelOrigin  : levelOrigin[item.dataName]" />
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
    </div>
  </div>
</template>
<script>
import { bus } from '../../main.js'
import CountDown from './components/CountDown'
import OneDataTemplate from './components/OneDataTemplate'
import TmDataTemplate from './components/TmDataTemplate'
import TwoDataTemplate from './components/TwoDataTemplate'
import axios from 'axios'
export default {
  name: '',
  props: {},
  components: {
    CountDown,
    OneDataTemplate,
    TmDataTemplate,
    TwoDataTemplate,
    levelOne: () => import('./components/level1/levelOne.vue'), // 1
    levelOneEn: () => import('./components/level1/levelOneEn.vue'), // 1 en
    levelTwo: () => import('./components/level2/levelTwo'), // 2
    levelTwoEn: () => import('./components/level2/levelTwoEn'), // 2 en
    levelThree: () => import('./components/level3/levelThree'), // 3
    levelThreeEn: () => import('./components/level3/levelThreeEn'), // 3 en
    levelFour: () => import('./components/level4/levelFour'), // 4
    levelFourEn: () => import('./components/level4/levelFourEn'), // 4 en
    levelFive: () => import('./components/level5/levelFive'), // 5
    levelFiveEn: () => import('./components/level5/levelFiveEn'), // 5en
    levelSix: () => import('./components/level6/levelSix'), // 6
    levelSixEn: () => import('./components/level6/levelSixEn'), // 6 en
    levelSeven: () => import('./components/level7/levelSeven'), // 7
    levelSevenEn: () => import('./components/level7/levelSevenEn'), // 7
    levelEight: () => import('./components/level8/levelEight'), // 8
    levelEightEn: () => import('./components/level8/levelEightEn'), // 8 en
    levelNine: () => import('./components/level9/levelNine'), // 9
    levelNineEn: () => import('./components/level9/levelNineEn'), // 9 en
    levelTen: () => import('./components/level10/levelTen'), // 10
    levelTenEn: () => import('./components/level10/levelTenEn'), // 10
    levelEleven: () => import('./components/level11/levelEleven'), //11
    levelElevenEn: () => import('./components/level11/levelElevenEn'), //11
    levelTwelve: () => import('./components/level12/levelTwelve'), // 12
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper
    },
    languageSwitchComponents() {
      let list = [
        { name: 'levelOne', dataName: 'overallSales' }, // 1
        { name: 'levelTwo', dataName: 'timeSales' }, // 2
        { name: 'levelThree', dataName: 'default' }, // 3
        { name: 'levelFour', dataName: 'default' }, // 4
        { name: 'levelFive', dataName: 'default' }, // 5
        { name: 'levelSix', dataName: 'default' }, // 6
        { name: 'levelSeven', dataName: 'topCategory' }, // 7
        { name: 'levelEight', dataName: 'brandSku' }, // 8
        { name: 'levelNine', dataName: 'topCategoryBrand' }, // 9
        { name: 'levelTen', dataName: 'default' }, // 10
        { name: 'levelEleven', dataName: 'default' }, // 11
        { name: 'levelTwelve', dataName: 'default' }, // 12
      ]
      if (this.language !== 'ZN') {
        let enList = list.slice(0, 11)
        list = enList.map((item) => ({
          ...item,
          name: `${item.name}En`,
        }))
      }
      return list
    },
  },
  data() {
    return {
      openCountDownModel: false,
      componentIsName: 'CountDown',
      targetDate: new Date('2023-11-11T01:59:59'),
      // targetDate: new Date('2023-11-10T20:49:59'),
      swiperOptions: {
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets',
          clickable: true,
        },
        direction: 'vertical',
        scrollbar: '.swiper-scrollbar',
        mousewheel: true,
      },
      language: 'ZN',
      levelOrigin: {},
      countDownOpenState: false,
      dataDpOpenState: false,
    }
  },
  created() {
    this.init()
    bus.$on('languageSwitch', (language) => {
      this.language = language
    })
    console.log('reducedTime', this.reducedTime())
    if (this.reducedTime()) {
      this.countDownOpenState = true
    } else {
      this.dataDpOpenState = true
    }
  },
  methods: {
    changeTemplat(name) {
      this.componentIsName = name
    },
    async init() {
      let url = 'apis/eleven/eleven'
      if (process.env.NODE_ENV !== 'development') {
        // url = 'http://58.18.32.146:17008/eleven/eleven'
        url = 'https://code.synlen.com/eleven/eleven'
      }
      axios.get(url).then((res) => {
        let { code, body, msg } = res.data
        if (code == 200) {
          this.levelOrigin = body
        }
      })
    },
    open() {
      this.countDownOpenState = false
      this.dataDpOpenState = true
    },

    reducedTime() {
      const currentDate = new Date()
      return this.targetDate > currentDate
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@/style/rem.scss';
.video-background {
  width: 100%;
  height: 100%;
  line-height: 1;
}
.open-count-cown-model {
  background: #333;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 11;
}
.open-entrance {
  height: 100%;
  display: flex;
  align-items: center;
  .double-11-entrance {
    width: 150px;
    cursor: pointer;
  }
}
video {
  position: absolute;
  z-index: 11;
  top: 50%;
  left: 50%;
  width: 100vw;
  height: auto;
  transform: translateX(-50%) translateY(-50%);
}

.main {
  position: absolute;
  z-index: 12;
  width: 100vw;
  height: 100vh;
  .title {
    font-size: 60px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #ffffff;

    background: linear-gradient(180deg, #ffffff 0%, #ffd580 99.5849609375%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-top: rem(100);
  }
  .time-hint {
    height: rem(220);
    line-height: rem(220);
    margin-top: rem(50);
    margin-bottom: rem(20);
    font-size: rem(120);
    font-family: Alimama ShuHeiTi;
    font-weight: bold;
    color: #ffffff;
    text-shadow: 0px 4px 0px rgba(255, 208, 99, 0.25);
    background: linear-gradient(
      0deg,
      #654227 0%,
      #eda557 2.83203125%,
      #f9ff89 18.505859375%,
      #f8ff84 50.68359375%,
      #ffd487 65.2587890625%,
      #ae843d 78.7841796875%,
      #f8ff86 86.62109375%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
.count-down {
  min-height: rem(200);
  display: flex;
  justify-content: center;
  margin-bottom: rem(80);
}
.card-view {
  display: flex;
  justify-content: space-between;
  padding: 0 8%;
  .card-item {
    width: rem(480);
    height: rem(280);
    padding: rem(20) rem(40);
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    &:hover {
      -webkit-transform: scale(1.1);
      transform: scale(1.1);
    }
  }
  .text {
    flex: 1;
    font-size: rem(32);
    font-family: Source Han Sans CN;
    font-weight: 500;
    text-align: left;
  }
}
.text-color {
  background: linear-gradient(
    90deg,
    #654227 0%,
    #eda557 2.83203125%,
    #f9ff89 18.505859375%,
    #f8ff84 50.68359375%,
    #ffd487 65.2587890625%,
    #ae843d 78.7841796875%,
    #f8ff86 86.62109375%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.btn {
  width: rem(134);
  height: rem(45);
  line-height: rem(45);
  font-size: rem(20);
  margin-bottom: rem(20);
  cursor: pointer;
}
.usable {
  background: url('../../assets/speaial_activity_img/card_bg.png') no-repeat
    center;
  background-size: 100% 100%;
  .btn {
    background: url('../../assets/speaial_activity_img/btn_bg.png') no-repeat
      center;
    background-size: 100% 100%;
    color: #fff2ce;
  }
}
.disabled {
  background: url('../../assets/speaial_activity_img/card_bg_disabled.png')
    no-repeat center;
  background-size: 100% 100%;
  .btn {
    background: url('../../assets/speaial_activity_img/btn_bg_disabled.png')
      no-repeat center;
    background-size: 100% 100%;
    color: #ebe8e2;
    cursor: not-allowed;
  }
}

//倒计时
.swiper-box {
  height: 100%;
  position: relative;
}
.swiper-wrapper {
  width: 100%;
  height: 100%;
}

.swiper-pagination {
  right: 10px;
  height: 200px;
  width: 30px;
  top: 50%;
  transform: translateY(-50%);
  /deep/ .swiper-pagination-bullet {
    background: #ff6600;
  }
}
</style>

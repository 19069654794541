import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/specialActivity/Home.vue";

Vue.use(VueRouter);

const routes = [{
  path: "/",
  name: "Home",
  component: Home
}];
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});
router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (localStorage.getItem('language') == 'EN') {
    document.title = "SYNTUN";
  } else {
    document.title = "星图数据";
  }
  next();
})

export default router;